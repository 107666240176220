// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import {debounce} from '@github/mini-throttle'

// Used to calculate the padding-top of the header menu when it's fixed so that it doesn't overlap the content above the header
observe('.js-nav-padding-recalculate', element => {
  let headerMenuOpened = false
  const headerMenu = document.querySelector('.js-header-menu') as HTMLElement
  const headerWrapper = document.querySelector('.js-header-wrapper') as HTMLElement
  const header = document.querySelector('.js-header') as HTMLElement
  const preventFocusOnElements = document.querySelectorAll('.js-prevent-focus-on-mobile-nav')

  const recalculateNavPadding = () => {
    if (!headerMenu || !headerWrapper || !header) return

    let height = headerWrapper.clientHeight
    const headerIsAbsolute = getComputedStyle(header).position === 'absolute'
    if (headerIsAbsolute) height += header.clientHeight
    height -= window.scrollY
    headerMenu.style.setProperty('padding-top', `${height}px`)
  }
  const recalculateNavPaddingDebounced = debounce(recalculateNavPadding, 10)

  const escapeKeyHandler = (event: KeyboardEvent) => {
    // eslint-disable-next-line @github-ui/ui-commands/no-manual-shortcut-logic
    if (event.key === 'Escape' && headerMenuOpened) {
      ;(element as HTMLElement).click()
      ;(element as HTMLElement).focus()
    }
  }

  window.removeEventListener('resize', recalculateNavPadding)
  document.removeEventListener('keydown', escapeKeyHandler)

  const recalculateIfMenuNotOpened = () => {
    if (!headerMenuOpened) recalculateNavPadding()
  }

  const recalculateIfMenuOpened = () => {
    if (headerMenuOpened) recalculateNavPadding()
  }

  document.addEventListener('global-banner:close', recalculateIfMenuOpened)

  element.addEventListener('mousedown', recalculateIfMenuNotOpened)
  element.addEventListener('touchstart', recalculateIfMenuNotOpened, {passive: true})
  element.addEventListener('keydown', recalculateIfMenuNotOpened)

  const menuToggledHandler = (event: CustomEvent) => {
    if (event.target !== header) return
    headerMenuOpened = event.detail.open
    if (headerMenuOpened) {
      // eslint-disable-next-line github/prefer-observers
      window.addEventListener('resize', recalculateNavPaddingDebounced)
      document.addEventListener('keydown', escapeKeyHandler)
      if (preventFocusOnElements) for (const el of preventFocusOnElements) el.setAttribute('tabindex', '-1')
    } else {
      window.removeEventListener('resize', recalculateNavPaddingDebounced)
      document.removeEventListener('keydown', escapeKeyHandler)
      if (preventFocusOnElements) for (const el of preventFocusOnElements) el.removeAttribute('tabindex')
    }
  }

  header.addEventListener('details:toggled', menuToggledHandler as EventListener, false)
})

// Force light theme on the user drawer side panel only on marketing pages
observe('#__primerPortalRoot__, .js-header [data-target="deferred-side-panel.panel"]', element => {
  element.setAttribute('data-color-mode', 'light')
  element.setAttribute('data-light-theme', 'light')
  element.setAttribute('data-dark-theme', 'dark')
})

observe('.js-header-menu-focus-trap', element => {
  element.addEventListener('focusin', () => {
    const headerMenu = document.querySelector('.js-header-menu-toggle') as HTMLElement
    if (headerMenu) headerMenu.focus()
  })
})
